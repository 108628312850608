import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
} from '@octano/global-ui';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { useParameters } from '../../../../../../../hooks/useParameters';
import { useValidations } from '../../../../../../../hooks/useValidations';

interface SearchUserValues {
  country: string;
  passport: string;
}

interface SearchUserForm {
  country: SelectOptionType | undefined;
  passport: string | undefined;
}

interface SearchUserFormByPassportProps {
  prefix: string;
  defaultValues: SearchUserForm;
  onSubmit: (values: SearchUserValues) => void;
}

export const SearchUserFormByPassport = (
  props: SearchUserFormByPassportProps,
) => {
  const { onSubmit: onSubmitProp, defaultValues, prefix } = props;
  const { t } = useTranslation();
  const { control, handleSubmit, formState } = useForm<SearchUserForm>({
    defaultValues,
    mode: 'all',
  });
  const { countryOptions } = useParameters();
  const { validateTextNotEmpty } = useValidations();

  const onSubmit = useCallback(
    (values) => {
      onSubmitProp({
        country: values.country.value as string,
        passport: values.passport,
      });
    },
    [onSubmitProp],
  );

  const { isDirty, isValid } = formState;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <label>{t(`${prefix}.passport`)}</label>
      <Row>
        <Col md={10} lg={8}>
          <Row>
            <Col xs={4} sm={4} className="pr-1">
              <OutlinedSelect
                name="country"
                isSearchable={true}
                placeholder={t(`${prefix}.country`)}
                control={control}
                options={countryOptions}
                rules={{ required: 'Campo requerido' }}
              />
            </Col>
            <Col className="pl-1">
              <TextOutlinedInput
                placeholder={t(`${prefix}.passport`)}
                name="passport"
                control={control}
                rules={{
                  validate: validateTextNotEmpty,
                  pattern: {
                    value: /^[0-9a-zA-Z]+$/,
                    message: t('common.validations.invalidAlphanumeric'),
                  },
                }}
              />
            </Col>
            <Col xs={3} sm={3} style={{ padding: '0px', paddingRight: '16px' }}>
              <Button
                size="sm"
                type="submit"
                text={t(`common.actions.search`)}
                fullwidth
                disabled={!isDirty || !isValid}
                style={{ height: '42px' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
