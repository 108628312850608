import { clean } from 'rut.js';
import { removeSymbolsPassport } from '../../../../../../utils/cleanPassport';

import {
  AdminAccountForm,
  CreateAdminAccountBody,
  UpdateAdminAccountBody,
} from '../types';

export const adminAccountBodyAdapter = (
  adminAccount: AdminAccountForm,
  countryOptions?: any,
): CreateAdminAccountBody => {
  const countryId = adminAccount.passportCountry?.value;
  const nationalityId = adminAccount.nationality?.value;

  return {
    type: adminAccount.rut && adminAccount.rut !== '' ? 'rut' : 'passport',
    name: adminAccount.names,
    run: adminAccount.rut !== '' ? clean(adminAccount.rut) : undefined,
    passportNumber: removeSymbolsPassport(adminAccount?.passportNumber),
    countryId: countryId,
    nationalityId: nationalityId,
    birthday: adminAccount.birthdate
      ? new Date(adminAccount.birthdate).toISOString().split('T')[0]
      : '',
    paternalLastName: adminAccount.lastnamePather,
    maternalLastName: adminAccount.lastnameMother,
    email: adminAccount.email,
    personalEmail: adminAccount.personalEmail,
    phone: adminAccount.phone,
  };
};

export const adminAccountUpdateBodyAdapter = (
  values: AdminAccountForm,
): UpdateAdminAccountBody => {
  const nationalityId = values.nationality?.value;

  return {
    id: values.id,
    name: values.names,
    maternalLastName: values.lastnameMother,
    paternalLastName: values.lastnamePather,
    birthday: values.birthdate
      ? new Date(values.birthdate).toISOString().split('T')[0]
      : '',
    nationalityId: nationalityId,
    email: values.email,
    personalEmail: values.personalEmail,
    phone: values.phone,
    rolesId: values.role?.value,
  };
};
